import './index.scss'

import Swiper from 'swiper/js/swiper.min'
import 'swiper/css/swiper.min.css'

// banner
!(() => {
  let $titleEl = $('.comp-header-title p')
  let $paginationEl = $('.comp-header-title :last-child')
  let $videoEl = $('.banner-video')
  let $videoCloseElArr = $('[data-close="banner-video"]')
  let swiper = new Swiper('.banner-swiper', {
    loop: true,
    speed: 800,
    autoplay: {
      delay: 3000,
      disableOnInteraction: false
    },
    grabCursor: true,
    // 开启才可以使用 slide 元素的 progress 属性
    watchSlidesProgress: true,
    pagination: {
      el: $paginationEl,
      bulletClass: 'asdlwidzz',
      modifierClass: 'asd87w7d8z',
      bulletActiveClass: 'active',
      clickable: true,
      renderBullet (i, className) {
        return `<span class="${ className }"></span>`
      }
    },
    on: {
      transitionStart () {
        let title = $(this.slides[ this.activeIndex ])
          .data('title')

        $titleEl.html(title)
      },
      // 被背景设置 transition
      setTransition (speed) {
        for (var i = 0; i < this.slides.length; i++) {
          this.slides[ i ].querySelector('img').style.transition =
            speed + 'ms'
        }
      },
      // 根据 slideProgress 实现视差效果
      progress () {
        for (let i = 0; i < this.slides.length; i++) {
          let slideProgress = this.slides[ i ].progress
          let innerOffset = this.width * 0.5
          let innerTranslate = slideProgress * innerOffset
          this.slides[ i ]
            .querySelector('img')
            .style
            .transform = 'translate3d(' + innerTranslate + 'px, 0, 0)'
        }
      }
    }
  })

  $videoCloseElArr.on('click', function () {
    $videoEl.fadeOut(400)
  })
})()

// product
!(() => {
  let swiper = new Swiper('.product-swiper', {
    effect: 'fade',
    simulateTouch: false,
    pagination: {
      el: '.product-pagination',
      bulletClass: 'asdlwidzzasdwd',
      bulletActiveClass: 'active',
      modifierClass: 'asdoiwodzzz',
      clickable: true,
      renderBullet (i, className) {
        return `
          <a class="comp-link ${ className }" href="javascript:">0${ i + 1 }</a>
        `
      }
    }
  })
})()

// field
!(() => {
  let $navGroupElArr = $('.field-nav-group')
  let $navDescElArr = $('.field-nav-desc')
  let defaultI = $navGroupElArr.index($navGroupElArr.filter('.active'))
  let timer = null;
  let swiper = new Swiper('.field-media-swiper', {
    autoplay: {
      disableOnInteraction: false,
      delay: 5000
    },
    effect: 'fade',
    fadeEffect: {
      crossFade: true
    },
    grabCursor: true,
    on: {
      transitionStart () {
        let i = this.realIndex

        clearTimeout(timer);
        timer = setTimeout(function (params) {
          $navGroupElArr.removeClass('active').eq(i).addClass('active')
          $navDescElArr.stop().slideUp(200)
          $navDescElArr.eq(i).stop().slideDown(200)
        }, 180)
      }
    }
  })

  // init
  $navDescElArr.eq(defaultI).slideDown(0)

  $navGroupElArr.on('mouseenter', function () {
    let i = $navGroupElArr.index($(this))
    swiper.slideTo(i)
  })
})()
